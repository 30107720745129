import axios from "axios";

const apiUrl = "https://partners-backend.coinstats.app/api";
// const apiUrl = "http://localhost:2222/api";


class ApiService {
  static _authHttpRequest(method, url, body) {
    return axios({
      method: method,
      url: apiUrl + url,
      headers: {
        "Content-Type": "application/json",
        token: window.atob(localStorage.getItem("sessionToken")),
      },
      data: body,
    });
  }

  userLogin({username, password, captchaToken}) {
    return axios.post(`${apiUrl}/users/login`, {
      username,
      password,
      captchaToken
    });
  }

  userLogout() {
    ApiService._authHttpRequest("post", "/users/logout");

    localStorage.removeItem("sessionToken");
    localStorage.removeItem("username");
    localStorage.removeItem("displayName");
    localStorage.removeItem("email");
  }

  getRevenueData(skip = 0, limit = 100) {
    return ApiService._authHttpRequest(
      "get",
      `/revenue?skip=${skip}&limit=${limit}`
    );
  }

  getRevenueDateData(from, to, skip = 0, limit = 100) {
    return ApiService._authHttpRequest(
      "get",
      `/revenue/search?from=${from}&to=${to}&skip=${skip}&limit=${limit}`
    );
  }

  getTotalRevenueData() {
    return ApiService._authHttpRequest("get", `/revenue/total`);
  }
}

export default new ApiService();
