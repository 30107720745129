import React from "react";
import { Table, Spinner, Button } from "reactstrap";

import ApiService from "../../controller/ApiService";

import "./RevenueTable.css";

const limit = 100;
export default function RevenueTable({
  setIsLoading,
  isLoading,
  isDateRangeSelected,
  revenueTableData,
  setData,
  dateRangeData,
  currentPage,
  setCurrentPage,
}) {
  const formatTableDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  };

  const getDataByDate = async (skip, limit) => {
    const result = await ApiService.getRevenueDateData(
      dateRangeData.start,
      dateRangeData.end,
      skip,
      limit
    );
    return result.data;
  };

  const nextPage = async () => {
    setIsLoading(true);
    setCurrentPage(currentPage + 1);

    try {
      const skip = (currentPage + 1) * limit;

      let paginateItems;

      if (isDateRangeSelected) {
        paginateItems = await getDataByDate(skip, limit);
        setData(paginateItems);
      } else {
        const result = await ApiService.getRevenueData(skip, limit);
        setData(result.data);
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const prevPage = async () => {
    setIsLoading(true);
    setCurrentPage(currentPage - 1);

    try {
      const skip = (currentPage - 1) * limit;

      let paginateItems;

      if (isDateRangeSelected) {
        paginateItems = await getDataByDate(skip, limit);
        setData(paginateItems);
      } else {
        const result = await ApiService.getRevenueData(skip, limit);
        setData(result.data);
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div className="table-wrapper">
        <Table className="table-custom" striped bordered>
          <thead>
            <tr>
              <th>
                <span>Date</span>
              </th>
              <th>
                <span>User</span>
              </th>
              <th>
                <span>Influencer’s Revenue</span>
              </th>
            </tr>
          </thead>
          {!isLoading && revenueTableData?.length > 0 && (
            <tbody className="fixed-height">
              {revenueTableData.map((item, i) => (
                <tr
                  className={`table-item ${
                    item.payment_status !== "paid" || item.refund
                      ? "unpaid"
                      : ""
                  }`}
                  key={`${item.email}-${i}`}
                >
                  <td className="payment-status">
                    {formatTableDate(item.date)}
                  </td>
                  <td>{item.email}</td>
                  <td className="total">$ {item.total} </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
        {isLoading && (
          <div className="table-loader">
            <Spinner color="primary">Loading...</Spinner>
          </div>
        )}
        {!isLoading && !revenueTableData.length && (
          <div>
            <h2 className="error">No data</h2>
          </div>
        )}
      </div>
      <div className="table-pagination">
        <Button disabled={isLoading || currentPage === 0} onClick={prevPage}>
          Prev
        </Button>
        <Button
          disabled={isLoading || revenueTableData.length < 100}
          onClick={nextPage}
        >
          Next
        </Button>
      </div>
    </>
  );
}
