import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Footer from "./components/Footer/Footer";

function App() {
  const token = localStorage.getItem("sessionToken");
  const displayName = localStorage.getItem("displayName");
  const email = localStorage.getItem("email");
  const username = localStorage.getItem("username");

  const [userData, setUserData] = useState({
    token,
    displayName,
    email,
    username,
  });

  return (
    <BrowserRouter>
      <Header userData={userData} setUserData={setUserData} />
      <Routes>
        <Route
          exact
          path="/"
          element={token ? <Home /> : <Login setUserData={setUserData} />}
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
