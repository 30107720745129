import React, { useState, useEffect } from "react";

import Filter from "../../components/Filter/Filter";
import RevenueTable from "../../components/RevenueTable/RevenueTable";
import TotalTable from "../../components/TotalTable/TotalTable";

import ApiService from "../../controller/ApiService";

import "./Home.css";

function Home() {
  const [data, setData] = useState([]);
  const [isDateRangeSelected, setisDateRangeSelected] = useState(false);
  const [dateRangeData, setDateRangeData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getTableData();
  }, []);

  const getTableData = async () => {
    setIsLoading(true);
    try {
      const result = await ApiService.getRevenueData();
      setData(result.data);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="home">
      <div className="app-inner">
        <Filter
          setCurrentPage={setCurrentPage}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setisDateRangeSelected={setisDateRangeSelected}
          setData={setData}
          setDateRangeData={setDateRangeData}
        />
        <div className="timerange-table">
          <TotalTable timeRangeData={data} />
        </div>
        <div className="revenue-table">
          <p>It may take up to 3 hours for the board to update.</p>
          <RevenueTable
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            dateRangeData={dateRangeData}
            isDateRangeSelected={isDateRangeSelected}
            setData={setData}
            revenueTableData={data}
          />
        </div>
      </div>
    </div>
  );
}

export default Home;