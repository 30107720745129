import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavItem, NavLink } from "reactstrap";

import logo from "../../assets/images/logo.svg";
import ApiService from "../../controller/ApiService";

import "./Header.css";

export default function Header({ userData, setUserData }) {
  const logout = () => {
    try {
      setUserData({});
      return ApiService.userLogout();
    } catch (e) {
      throw console.log(e);
    }
  };

  return (
    <Navbar color="light" expand="md" light>
      <Link
        to="/"
        className="d-flex align-items-center fs-6 text-decoration-none text-black"
      >
        <img className="logo" alt="CoinStats Logo" src={logo} />
        {userData && userData.username && (
          <span className="ms-1">{`/ ${userData.username}`}</span>
        )}
      </Link>
      <div>
        <Nav className="me-auto" navbar>
          {userData && userData.username && (
            <NavItem>
              <NavLink
                onClick={() => {
                  logout();
                }}
              >
                Logout
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </div>
    </Navbar>
  );
}
