import React from "react";

import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-inner">
        <div className="footer-text">
          <span>
            All rights reserved © CoinStats {new Date().getFullYear()}
          </span>
        </div>
      </div>
    </div>
  );
}
