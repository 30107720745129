import React, { useState, useEffect } from "react";
import { Spinner, Table } from "reactstrap";
import ApiService from "../../controller/ApiService";

import "./TotalTable.css";

export default function TotalTable() {
  const [isLoading, setIsLoading] = useState(false);
  const [totalData, setTotalData] = useState({});

  useEffect(() => {
    getTotalInfo();
  }, []);

  const getTotalInfo = async () => {
    setIsLoading(true);
    try {
      const totalInfo = await ApiService.getTotalRevenueData();
      setTotalData(totalInfo.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const formatDateRange = (date) => {
    const from = new Date(date?.from * 1000);
    const to = new Date(date?.to * 1000);
    if (from && to) {
      return `${from.getDate()}.${
        from.getMonth() + 1
      }.${from.getFullYear()} - ${to.getDate()}.${
        to.getMonth() + 1
      }.${to.getFullYear()}`;
    }
    return "No Date";
  };

  return (
    <div className="table-wrapper">
      <Table striped bordered>
        <thead>
          <tr>
            <th>
              <span>Time Range</span>
            </th>
            <th>
              <span>Total Users</span>
            </th>
            <th>
              <span>Total Amount Earned by Influencer</span>
            </th>
          </tr>
        </thead>
        {!isLoading && totalData.count && (
          <tbody>
            <tr>
              <td>{formatDateRange(totalData?.range) || 0}</td>
              <td>{totalData?.count || 0}</td>
              <td>${totalData?.total || 0}</td>
            </tr>
          </tbody>
        )}
      </Table>
      {isLoading && (
        <div className="table-loader">
          <Spinner color="primary">Loading...</Spinner>
        </div>
      )}
      {!totalData.count && !isLoading && (
        <div>
          <h2 className="error">No data</h2>
        </div>
      )}
    </div>
  );
}
