import React, { useEffect, useState } from "react";
import ApiService from "../../controller/ApiService";
import { Link } from "react-router-dom";
import { Button, Input, Label } from "reactstrap";

import "./Login.css";
// import Geetest from "../../components/Geetest";

// const RECAPTCHA_ID = "6LfBO0gkAAAAALy5e9jVVlIsoisvjSywQIWOlWq3"; //Dev
const RECAPTCHA_ID = "6LdI4gkjAAAAABy1WuhhxXrCY9CWbjUCTXsLOmNL"; //Prod

export default function Login({ setUserData }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [authError, setAuthError] = useState("");
  const [isLoading, setIsLogining] = useState(false);
  // const [showCaptcha, setShowCaptcha] = useState(false);

  // const showGeetest = () => setShowCaptcha(true);

  const initReCaptcha = () => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_ID}`;
    document.body.appendChild(script);
  };

  useEffect(() => {
    initReCaptcha();
  }, []);

  const handleLogin = async () => {
    try {
      setIsLogining(true);
      window.grecaptcha.enterprise.ready(async function () {
        const captchaToken = await window.grecaptcha.enterprise.execute(
          RECAPTCHA_ID,
          { action: "submit" }
        );
        const result = await ApiService.userLogin(
          { username, password, captchaToken },
        );

        const user = result.data.result;
        localStorage.setItem("displayName", user.displayName);
        localStorage.setItem("email", user.email);
        localStorage.setItem("sessionToken", window.btoa(user.sessionToken));
        localStorage.setItem("username", user.username);
        setUserData(user);
      });
    } catch (error) {
      console.log(error.response);
      setAuthError(error.response ? error.response.data.message : error.message);
    }
    setIsLogining(false);
  };

  return (
    <div className="login">
      <div className="container-fluid">
        <div className="login-inner d-flex justify-content-center align-items-center flex-column">
          <h2 className="login-title h1">Login</h2>
          <div className="login-form">
            <div className="login-form-input">
              <Label>
                Username/email
                <Input
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  type="text"
                  placeholder="Username Or Email"
                />
              </Label>
            </div>
            <div className="login-form-input">
              <Label>
                Password
                <Input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  placeholder="Password"
                />
              </Label>
            </div>
            {authError && <p className="error-message">{authError}</p>}
            <Link to="/">
              <Button onClick={handleLogin}>Login</Button>
              {isLoading && 'Loading...'}
            </Link>
          </div>
          {/* <Geetest forceShow={showCaptcha} onSuccess={login} /> */}
        </div>
      </div>
    </div>
  );
}
