import React, { useState } from "react";

import "./Filter.css";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Button, Label } from "reactstrap";
import ApiService from "../../controller/ApiService";

export default function Filter({
  setisDateRangeSelected,
  setData,
  setDateRangeData,
  setIsLoading,
  isLoading,
  setCurrentPage
}) {
  const [startDate, setStartDate] = useState(new Date().getTime());
  const [endDate, setEndDate] = useState(0);

  const onChange = (dates) => {
    const [start, end] = dates;
    const startDateTimeStamp = new Date(start).getTime();
    const endDateTimeStamp = new Date(end).getTime();
    setStartDate(startDateTimeStamp);
    setEndDate(endDateTimeStamp);
    setDateRangeData({ start: startDateTimeStamp, end: endDateTimeStamp });
    setCurrentPage(0);
  };

  const getSearchData = async () => {
    setIsLoading(true);
    try {
      let searchData;
      if (startDate === 0 && endDate === 0) {
        searchData = await ApiService.getRevenueData(0, 100);
        setisDateRangeSelected(false);
      } else {
        searchData = await ApiService.getRevenueDateData(startDate, endDate);
        setisDateRangeSelected(true);
      }
      const searchDataResult = await searchData.data;
      setData(searchDataResult);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  return (
    <div className="filter">
      <div className="filter-datepicker">
        <Label>Date</Label>
        <div className="filter-datepicker-box">
          <div>
            <ReactDatePicker
              placeholderText="Choose Start Date"
              startDate={startDate}
              endDate={endDate}
              selected={startDate}
              onChange={onChange}
              dateFormat="dd/MM/yyyy"
              selectsRange
              isClearable
            />
          </div>
          <Button
            color="info"
            className="filter-button"
            onClick={getSearchData}
            disabled={isLoading}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}
